import {
  SmallCloseIcon,
  CloseIcon,
  SettingsIcon,
  RepeatIcon,
  ViewIcon,
  BellIcon,
  LinkIcon,
  ExternalLinkIcon,
  MinusIcon,
} from "@chakra-ui/icons";
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  Text,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

const MenuWithTool = ({ child, text, disabled }: any) => {
  return (
    <Tooltip
      isDisabled={disabled}
      hasArrow
      fontSize={"1em"}
      p={3}
      bg={"white"}
      textAlign={"center"}
      color={"black"}
      placement="top"
      label={
        <>
          <Text>{text}</Text>
        </>
      }
      aria-label="A tooltip"
    >
      <Box width="20px">
        {child}
      </Box>
    </Tooltip>
  );
};

export function SettingModal({ modalContents, colorToUse, onCloseClick }: any) {
  const OverlayOne = () => (
    <ModalOverlay
      // bg="blackAlpha.300"
      bg="whiteAlpha.100"
      backdropFilter="blur(8px) hue-rotate(-5deg)"
    />
  );

  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="2px"
    />
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [overlay, setOverlay] = React.useState(<OverlayOne />);

  return (
    <>
      <HStack>
        <MenuWithTool
          disabled={true}
          child={
            <BellIcon
              aria-label="settings"
              color={true ? "#888" : colorToUse}
            />
          }
          text="Toggle SMS alerts"
        />
        <MenuWithTool
          disabled={true}
          child={
            <LinkIcon
              aria-label="settings"
              color={true ? "#888" : colorToUse}
            />
          }
          text="Share this activity chart"
        />
        <MenuWithTool
          disabled={true}
          child={
            <RepeatIcon
              aria-label="settings"
              color={true ? "#888" : colorToUse}
            />
          }
          text="Reload activity chart"
        />
        <MenuWithTool
          child={
            <SettingsIcon
              color={colorToUse}
              aria-label="settings"
              onClick={() => {
                setOverlay(<OverlayOne />);
                onOpen();
              }}
            />
          }
          text="Chart settings"
        />
        <MenuWithTool
          child={
            <MinusIcon
              color={colorToUse}
              aria-label="settings"
              onClick={() => {
                console.log("should remove");
                onCloseClick();
              }}
            />
          }
          text="Remove from view"
        />
        ;
      </HStack>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        {overlay}
        <ModalContent pt={"2vh"} pb={"5vh"} maxW={"80vw"} width={"600px"}>
          <ModalHeader>Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{modalContents}</ModalBody>
          <ModalFooter>
            <Button colorScheme="whiteAlpha" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
