import {
  Button,
  Center,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Skeleton,
} from "@chakra-ui/react";
import React from "react";

export function ColorPicker({ isLoading, color, setColor }: any) {
  const colors = [
    "gray.500",
    "red.500",
    "gray.700",
    "green.500",
    "blue.500",
    "blue.800",
    "yellow.500",
    "orange.500",
    "purple.500",
    "pink.500",
  ];

  return (
    <>
      <Center marginTop={5}>
        <Popover variant="picker">
          <PopoverTrigger>
            <Skeleton isLoaded={!isLoading}>
              <Button
                aria-label={color}
                background={color}
                height="22px"
                width="22px"
                padding={0}
                minWidth="unset"
                borderRadius={3}
              ></Button>
            </Skeleton>
          </PopoverTrigger>
          <PopoverContent width="170px">
            <Skeleton isLoaded={!isLoading}>
              <PopoverArrow bg={color} />
              <PopoverCloseButton color="white" />
              <PopoverHeader
                height="100px"
                backgroundColor={color}
                borderTopLeftRadius={5}
                borderTopRightRadius={5}
                color="white"
              >
                <Center height="100%">{color}</Center>
              </PopoverHeader>
              <PopoverBody height="120px">
                <SimpleGrid columns={5} spacing={2}>
                  {colors.map((c) => (
                    <Button
                      key={c}
                      aria-label={c}
                      background={c}
                      height="22px"
                      width="22px"
                      padding={0}
                      minWidth="unset"
                      borderRadius={3}
                      _hover={{ background: c }}
                      onClick={() => {
                        setColor(c);
                      }}
                    ></Button>
                  ))}
                </SimpleGrid>
                <Input
                  borderRadius={3}
                  marginTop={3}
                  placeholder="red.100"
                  size="sm"
                  value={color}
                  onChange={(e) => {
                    setColor(e.target.value);
                  }}
                />
              </PopoverBody>
            </Skeleton>
          </PopoverContent>
        </Popover>
      </Center>
    </>
  );
}
