interface ActivityEvent {
  timestamp: Date;
  user: string;
  action: string;
}

let defaultBase = "https://tracky-v0.fly.dev";
if (window.location.host.startsWith("localhost")) {
  defaultBase = "http://localhost:8000";
}
export const BASE_URL = defaultBase;

export default class BackendClient {
  private static instance: BackendClient;
  // static BASE_URL = "http://localhost:8000";
  static BASE_URL = BASE_URL;
  // static BASE_URL = "https://tracky-v0.fly.dev";

  getHeaders = () => {
    const code = localStorage.getItem("code");
    const headers = {
      // Authorization: `Bearer ${code}`,
    };
    return headers;
  };

  private constructor() {
    // do something construct...
  }
  static getInstance() {
    if (!BackendClient.instance) {
      BackendClient.instance = new BackendClient();
      // ... any one time initialization goes here ...
    }
    return BackendClient.instance;
  }

  /* 
    Action: gets ...

    Request:
    interface AllDays {
      user: string;
      action: string;
    }

    Response:
    {
      "2022-09-21": 10,
      "2022-09-20": 8,
    }
  */
  getSquareDate = async ({ newWho, newWhat }: any) => {
    const options = {
      method: "GET",
      headers: this.getHeaders(),
      credentials: "include",
    };
    let result = await fetch(
      `${BackendClient.BASE_URL}/daily/${newWho}/${newWhat}`,
      options as any
    );
    let json = await result.json();
    return json;
  };

  /* 
    Action: adds a new event to the backed

    Request:

    Response:
    [
      {
        "id": 147,
        "user": "drbh",
        "action": "floss",
        "date": "2022-09-20T00:00:00.000Z"
      }
    ]
  */
  getDayData = async ({ newWho, newWhat, date }: any) => {
    const options = {
      method: "GET",
      headers: this.getHeaders(),
      credentials: "include",
    };
    let result = await fetch(
      `${BackendClient.BASE_URL}/daily/${newWho}/${newWhat}/${date}`,
      options as any
    );
    let json = await result.json();
    return json;
  };

  /* 
    Action: adds a new event to the backed

    Request:

    Response:
    {
      "activities": {
        "climb": {
          "color": "orange.500"
        },
        "floss": {
          "color": "red.500"
        },
        "catfood": {
          "color": "purple.500"
        },
        "solanitor": {
          "color": "pink.500"
        },
        "musictheory": {
          "color": "blue.500"
        },
        "sprucegrove": {
          "color": "green.500"
        }
      }
    }
  */
  getUserData = async ({ newWho }: any) => {
    const options = {
      method: "GET",
      headers: this.getHeaders(),
      credentials: "include",
    };

    // we can get who from the token
    let result = await fetch(
      `${BackendClient.BASE_URL}/v1/user`,
      // `${BackendClient.BASE_URL}/user/${newWho}`,
      options as any
    );
    let json = await result.json();
    return json;
  };

  /* 
    Action: adds a new event to the backed

    Request:
    {
      "activities": {
        "floss": {
          "color": "#FFFFFF"
        }
      }
    }
    Response:
    null
  */
  updateUserData = async ({ data, newWho }: any) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...this.getHeaders(),
      },
      credentials: "include",
      body: JSON.stringify(data),
    };
    let result = await fetch(
      `${BackendClient.BASE_URL}/v1/user/update`,
      // `${BackendClient.BASE_URL}/user/update/${newWho}`,
      options as any
    );
    let json = await result.json();
    return json;
  };

  /* 
    Action: adds a new event to the backed

    Request:

    Response:

    ...
  */
  handleRemoveEvent = async ({ newWho, newWhat, id }: any) => {
    const options = {
      method: "DELETE",
      headers: this.getHeaders(),
      credentials: "include"
    };
    let result = await fetch(
      `${BackendClient.BASE_URL}/daily/${newWho}/${newWhat}/${id}`,
      options as any
    );
    let json = await result.json();
    return json;
  };

  /* 
    Action: adds a new event to the backed

    Request:
    {
      "user": "drbh",
      "action": "brush",
      "timestamp": 1662232873
    }

    interface ActivityEvent {
      timestamp: Date;
      user: string;
      action: string;
    }

    Response:
    {
      "message": true
    }
  */
  handleAddNewEvent = async ({ data }: { data: ActivityEvent }) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...this.getHeaders(),
      },
      credentials: "include",
      body: JSON.stringify(data),
    };
    let result = await fetch(`${BackendClient.BASE_URL}/track`, options as any);
    let json = await result.json();
    return json;
  };
}
