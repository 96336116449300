import { StarIcon } from "@chakra-ui/icons";
import {
  Box,
  Badge,
  Button,
  ButtonGroup,
  Center,
  Circle,
  CloseButton,
  Editable,
  EditableInput,
  EditablePreview,
  HStack,
  Input,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  Portal,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useNumberInput,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import { theme } from "@chakra-ui/theme";
import React from "react";

import { SettingModal } from "./SettingModal";
import { ColorPicker } from "./ColorPicker";
import { IconPicker } from "./IconPicker";
import BackendClient from "../services/backend";

// export const BASE_URL = "https://tracky-v0.fly.dev";
export const BASE_URL = "http://localhost:3030/";

export function HookUsage() {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 3,
      min: 1,
      max: 5,
      precision: 0,
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <HStack maxW="320px">
      <Button colorScheme="whiteAlpha" {...inc}>
        +
      </Button>
      <Input boxShadow="none" {...input} />
      <Button colorScheme="whiteAlpha" {...dec}>
        -
      </Button>
    </HStack>
  );
}

export const ActivityChart = ({
  isHidden,
  initalWho,
  initalWhat,
  onTargetUpdate,
  onCloseClick,
  //
  stopLoad,
  borderLess,
  badgeLess,
  headerLess,
}: any) => {
  const [newWho, setNewWho]: any = React.useState(initalWho);
  const [newWhat, setNewWhat]: any = React.useState(initalWhat);

  const initialFocusRef: any = React.useRef();
  const [pos, setPos]: any = React.useState({
    top: 0,
    left: 0,
    index: null,
    date: null,
  });
  const [user, setUser]: any = React.useState(null);
  const [activity, setActivity]: any = React.useState([]); //["2018-06-12T19:30"]);
  const [isLoading, setIsLoading]: any = React.useState<boolean>(false);
  const [isSingleDayLoading, setIsSingleDayLoading]: any =
    React.useState<boolean>(false);
  const [isMainLoading, setIsMainLoading]: any = React.useState<boolean>(false);
  const [data, setData]: any = React.useState<any>([]);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [color, setColor] = React.useState("yellow.500");

  const handleRemove = (index: number) => {
    const newActivity = [...activity];
    newActivity.splice(index, 1);
    setActivity(newActivity);
  };

  const getSquareDate = () => {
    setIsLoading(true);
    BackendClient.getInstance()
      .getSquareDate({ newWho, newWhat })
      .then((response) => {
        setIsLoading(false);
        setIsMainLoading(false);
        setData(response);
      })
      .catch((err) => console.error(err));
  };

  const getDayData = () => {
    // setIsLoading(true);
    setIsSingleDayLoading(true);
    console.log("Getting single date", pos.date);
    // const options = { method: "GET" };
    // fetch(`${BASE_URL}/daily/${newWho}/${newWhat}/${pos.date}`, options)
    // .then((response) => response.json())
    BackendClient.getInstance()
      .getDayData({ newWho, newWhat, date: pos.date })
      .then((response) => {
        // setIsLoading(false);
        setIsSingleDayLoading(false);
        setActivity(response);
      })
      .catch((err) => console.error(err));
  };

  const getUserData = () => {
    setIsLoading(true);
    console.log("Getting User data", pos.date);
    // const options = { method: "GET" };
    // fetch(`${BASE_URL}/user/${newWho}`, options)
    //   .then((response) => response.json())
    BackendClient.getInstance()
      .getUserData({ newWho })
      .then((response) => {
        setIsLoading(false);
        setIsMainLoading(false);
        setUser(response);
      })
      .catch((err) => console.error(err));
  };

  const setUserData = ({ data }: any) => {
    // setIsLoading(true);
    setIsMainLoading(true);
    console.log("Setting User data", pos.date);
    // const options = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // };

    // fetch(`${BASE_URL}/user/update/${newWho}`, options)
    // .then((response) => response.json())
    BackendClient.getInstance()
      .updateUserData({ data, newWho })
      .then((response) => {
        // setIsLoading(false);
        getUserData();
        // setUser(response);
      })
      .catch((err) => console.error(err));
  };

  const handleRemoveEvent = (id: any) => {
    setIsSingleDayLoading(true);
    // const options = {
    //   method: "DELETE",
    // };
    // fetch(`${BASE_URL}/daily/${newWho}/${newWhat}/${id}`, options)
    //   .then((response) => response.json())
    BackendClient.getInstance()
      .handleRemoveEvent({ newWho, newWhat, id })
      .then((response) => {
        console.log("Removed event and getting data");
        getDayData();
        getSquareDate();
      })
      .catch((err) => console.error(err));
  };

  const handleAddNewEvent = () => {
    setIsLoading(true);
    // const newDate = new Date().toISOString().slice(0, 16);
    // const newActivity = [...activity, newDate];
    // setActivity(newActivity);
    setIsSingleDayLoading(true);
    // const options = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     timestamp: pos.date,
    //     user: newWho,
    //     action: newWhat,
    //   }),
    // };
    BackendClient.getInstance()
      .handleAddNewEvent({
        data: {
          timestamp: pos.date,
          user: newWho,
          action: newWhat,
        },
      })
      .then((response) => {
        console.log("Added new event and getting data");
        getDayData();
        getSquareDate();
      })
      .catch((err) => console.error(err));
  };

  React.useEffect(() => {
    setIsMainLoading(true);
    getUserData();
    // getSquareDate();
  }, []);

  React.useEffect(() => {
    if (pos.date) {
      getDayData();
    }
  }, [pos]);

  React.useEffect(() => {
    console.log(newWhat);

    const timeoutID = setTimeout(() => {
      console.log(Date.now(), "update", newWhat);
      setIsMainLoading(true);

      onTargetUpdate({ newWho, newWhat });

      // navigation(`${newWho}/${newWhat}`);
      getUserData();
      getSquareDate();

      // console.log({ newWho, newWhat });
      // console.log({ who, what });
    }, 1000);
    return () => clearTimeout(timeoutID);
  }, [newWho, newWhat]);

  // find index of last column
  const now = new Date();
  const dayOfWeekNumber = now.getDay();
  const utcDayOfWeekNumber = now.getUTCDay();

  const offsetFromUtcBy = utcDayOfWeekNumber - dayOfWeekNumber;

  // boxes
  // 105 0 Sun
  // 106 1 Mon
  // 107 2 Tus
  // 108 3 Wed
  // 109 4 Thur
  // 110 5 Fri
  // 111 6 Sat
  const endIndex = 105 + dayOfWeekNumber + offsetFromUtcBy;

  // 112 = 7 days * 16 weeks
  const numberOfBoxes = 112;

  const indexToDate = (index: number) => {
    const distanceFromToday = endIndex - index;
    const tempDate = new Date();
    tempDate.setDate(tempDate.getDate() - distanceFromToday);
    return tempDate.toISOString().slice(0, 10);
  };

  // const relativeDate = indexToDate(i);
  let weekCounts: any = {};

  [...Array(7)].map((_: any, dayIndex: number) => {
    return [...Array(16)].map((_: any, weekIndex: number) => {
      const index = dayIndex + weekIndex * 7;
      const date = indexToDate(index);

      const count = data[date];
      // console.log(weekIndex, count)
      if (!weekCounts[weekIndex]) weekCounts[weekIndex] = 0;
      weekCounts[weekIndex] += count ? count : 0;
    });
  });

  let colorToUse = "#718096";

  let activityChartOptions =
    user && user.activities && user.activities[newWhat]
      ? user.activities[newWhat]
      : { color: colorToUse };

  let colorSplit = user ? activityChartOptions.color.split(".") : []; // color.split(".");

  if (colorSplit.length > 1) {
    let [_color, _level] = colorSplit;

    colorToUse = (theme.colors as any)[_color][_level];
  }

  // const boxWidth = (window.innerWidth - 6 * 16) / 25 + "px";
  const boxWidth = window.innerWidth / 40 + "px";
  const boxWidthNumber = window.innerWidth / 40;
  // const whenWidthIsLarge = window.innerWidth > 1000;
  const whenWidthIsLarge = false;

  return (
    <Stack hidden={isHidden}>
      <Center>
        <Box
          px={"2%"}
          py={"2%"}
          borderRadius={4}
          shadow={borderLess ? "none" : "lg"}
        >
          <Stack mt={"2px"}>
            {!headerLess && (
              <HStack width="100%">
                <Circle
                  ml={1}
                  size={boxWidth}
                  backgroundColor={colorToUse + "88"}
                >
                  <StarIcon
                    fontSize={boxWidthNumber / 2 + "px"}
                    color={colorToUse + "FF"}
                  />
                </Circle>

                <Box width={4} />

                {false && (
                  <Text
                    fontSize={"1em"}
                    fontWeight="semibold"
                    color={colorToUse + "BB"}
                  >
                    @&nbsp;&nbsp;{newWho}&nbsp;&nbsp;/
                    {/* </Text>

                <Tooltip
                  hasArrow
                  fontSize={"1em"}
                  p={3}
                  bg={"white"}
                  textAlign={"center"}
                  color={"black"}
                  placement="top"
                  label={
                    <>
                      <Stack>
                        <Text>who this chart is for</Text>
                        <small>edit me to change the chart</small>
                      </Stack>
                    </>
                  }
                  aria-label="A tooltip"
                >
                  <Editable
                    display="flex"
                    onChange={(e: any) => {
                      // pathname.replace("drbh", e);
                      setNewWho(e);
                    }}
                    value={newWho}
                    fontSize={"1em"}
                    fontWeight="semibold"
                    // color="white"
                    selectAllOnFocus={false}
                    color={colorToUse}
                  >
                    <EditablePreview />
                    <Box
                      as={EditableInput}
                      size={newWho.length}
                      boxShadow={"none !important"}
                      width="fit-content"
                    />
                  </Editable>
                </Tooltip>
                <Text
                  fontSize={"1em"}
                  fontWeight="semibold"
                  color={colorToUse + "BB"}
                > */}
                  </Text>
                )}
                <Tooltip
                  hasArrow
                  fontSize={"1em"}
                  p={3}
                  bg={"white"}
                  textAlign={"center"}
                  color={"black"}
                  placement="top"
                  label={
                    <>
                      <Stack>
                        <Text>what this chart is about</Text>
                        <small>edit me to change the chart</small>
                      </Stack>
                    </>
                  }
                  aria-label="A tooltip"
                >
                  <Editable
                    onChange={(e: any) => {
                      setNewWhat(e.toLowerCase());
                    }}
                    defaultValue={newWhat}
                    fontSize={"1em"}
                    fontWeight="bold"
                    // color="white"
                    selectAllOnFocus={false}
                    color={colorToUse}
                    width="100%"
                  >
                    <EditablePreview width="fit-content" />
                    <Box
                      as={EditableInput}
                      size={newWhat ? newWhat.length : 0}
                      boxShadow={"none !important"}
                    />
                  </Editable>
                </Tooltip>
                <Box width="100%" />
                <Box pr={2}>
                  <SettingModal
                    colorToUse={colorToUse}
                    onCloseClick={onCloseClick}
                    modalContents={
                      <Stack>
                        <HStack>
                          <Text>Choose a color</Text>
                          <ColorPicker
                            isLoading={isMainLoading}
                            color={colorToUse}
                            setColor={(color: string) => {
                              // setColor(color)
                              const newActivites = {
                                ...user.activities,
                                [newWhat]: {
                                  ...user[newWhat],
                                  color,
                                },
                              };
                              const newData = {
                                ...user,
                                activities: newActivites,
                              };
                              setUserData({ data: newData });
                            }}
                          />
                        </HStack>
                        <HStack>
                          <Text>Days for star</Text>
                          <HookUsage />
                        </HStack>
                        <HStack>
                          <Text>Icon for chart</Text>
                          <IconPicker
                            color={colorToUse}
                            isLoading={false}
                            icon={<StarIcon color={colorToUse + "FF"} />}
                            setIcon={(icon: string) => {
                              console.log(icon);
                              const newActivites = {
                                ...user.activities,
                                [newWhat]: {
                                  ...user[newWhat],
                                  icon,
                                },
                              };
                              const newData = {
                                ...user,
                                activities: newActivites,
                              };

                              console.log(newData);
                              // setUserData({ data: newData });
                            }}
                          />
                        </HStack>
                      </Stack>
                    }
                  />
                </Box>
              </HStack>
            )}

            {!badgeLess && (
              <HStack>
                <Badge>Personal</Badge>
              </HStack>
            )}
          </Stack>
          <Modal
            isCentered
            // returnFocusOnClose={true}
            isOpen={isOpen}
            onClose={onClose}
            // placement="bottom"
            // closeOnBlur={true}
            // autoFocus={false}
          >
            <Portal>
              <Box
                position={"absolute"}
                top={pos.top + 10}
                left={pos.left - 160}
              >
                <ModalContent p={"10px"} maxW={"80vw"} width={"600px"}>
                  <ModalHeader pt={4} fontWeight="bold" border="0">
                    <Stack>
                      <Text fontSize="lg">{pos.date}</Text>
                    </Stack>
                  </ModalHeader>
                  {/* <PopoverCloseButton /> */}

                  <ModalBody>
                    <Stack>
                      <Skeleton isLoaded={!isSingleDayLoading}>
                        {(isSingleDayLoading
                          ? [{ date: new Date().toISOString(), id: 0 }]
                          : activity
                        ).map((time: any, index: number) => (
                          <HStack key={index} my={2}>
                            <Box>{time.id}</Box>
                            <Box width="100%">
                              <Input
                                placeholder="Select Date and Time"
                                size="md"
                                type="time"
                                step="1"
                                defaultValue={
                                  time.timestamp
                                    ? time.timestamp.slice(0, 16).slice(-5)
                                    : "NAN"
                                }
                              />
                            </Box>
                            <CloseButton
                              color="red"
                              onClick={() => {
                                handleRemoveEvent(time.id);
                                // handleRemove(index);
                              }}
                            />
                          </HStack>
                        ))}
                      </Skeleton>
                    </Stack>
                  </ModalBody>
                  <ModalFooter
                    border="0"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    pb={4}
                  >
                    <Box fontSize="sm">{activity.length} events logged</Box>
                    <ButtonGroup size="sm">
                      <Button colorScheme="green" onClick={handleAddNewEvent}>
                        Add
                      </Button>
                      <Button
                        variant="outline"
                        ref={initialFocusRef}
                        onClick={onClose}
                      >
                        close
                      </Button>
                    </ButtonGroup>
                  </ModalFooter>
                </ModalContent>
              </Box>
            </Portal>
          </Modal>

          <Box width="100%" mt={2} />
          <Skeleton
            isLoaded={!isMainLoading}
            // opacity={isMainLoading && 0.05}
            borderRadius={10}
          >
            <>
              {[...Array(7)].map((_: any, dayIndex: number) => (
                <HStack my={whenWidthIsLarge ? "8px" : "4px"} key={dayIndex}>
                  {[...Array(16)].map((_: any, weekIndex: number) => {
                    const index = dayIndex + weekIndex * 7;
                    const date = indexToDate(index);

                    const count = data[date];

                    const limit = 3;
                    let opIntensity = (255 / (limit - count))
                      .toString(16)
                      .split(".")[0];
                    if (opIntensity == "NaN") {
                      opIntensity = "33";
                    }

                    return (
                      <Tooltip
                        key={weekIndex}
                        hasArrow
                        fontSize={"14pt"}
                        fontWeight={"bold"}
                        p={3}
                        bg={"white"}
                        textAlign={"center"}
                        color={"black"}
                        placement="top"
                        label={`${date}`}
                      >
                        <Box
                          key={weekIndex}
                          onClick={(e: any) => {
                            if (index > endIndex - offsetFromUtcBy) return;

                            const { top, left } =
                              e.target.getBoundingClientRect();
                            setPos({
                              top: whenWidthIsLarge ? top + 30 : "50vh",
                              left: whenWidthIsLarge
                                ? left + 30
                                : window.innerWidth / 2,
                              index,
                              date,
                            });
                            onToggle();
                          }}
                          borderRadius={"10%"}
                          backgroundColor={
                            index > endIndex - offsetFromUtcBy
                              ? `${colorToUse}09`
                              : colorToUse + opIntensity
                          }
                          borderColor={colorToUse}
                          borderWidth={isOpen && index == pos.index ? 2 : 0}
                          width={whenWidthIsLarge ? "3vw" : boxWidth}
                          height={whenWidthIsLarge ? "3vw" : boxWidth}
                          fontSize={8}
                          mx={
                            whenWidthIsLarge
                              ? "4px !important"
                              : "2px !important"
                          }
                        ></Box>
                      </Tooltip>
                    );
                  })}
                </HStack>
              ))}
              {[...Array(1)].map((_: any, dayIndex: number) => (
                <HStack key={dayIndex}>
                  {[...Array(16)].map((_: any, weekIndex: number) => {
                    const index = weekIndex * 7;
                    const date = indexToDate(index);

                    const count = data[date];
                    const limit = 3;
                    let opIntensity = (255 / (limit - count))
                      .toString(16)
                      .split(".")[0];

                    if (opIntensity == "NaN") {
                      opIntensity = "33";
                    }

                    let endOfWeekDate: any = new Date(date);
                    endOfWeekDate.setDate(endOfWeekDate.getDate() + 6);
                    endOfWeekDate = endOfWeekDate.toISOString().slice(0, 10);

                    const awarded = weekCounts[weekIndex] >= 3 + dayIndex;
                    return (
                      <Box
                        key={weekIndex}
                        borderRadius={"20%"}
                        backgroundColor={"none"}
                        width={whenWidthIsLarge ? "3vw" : boxWidth}
                        height={whenWidthIsLarge ? "3vw" : boxWidth}
                        // fontSize={8}
                        mx={
                          whenWidthIsLarge ? "4px !important" : "2px !important"
                        }
                        shadow="none"
                      >
                        <Center
                          fontSize={whenWidthIsLarge ? "2vw" : boxWidth}
                          height={whenWidthIsLarge ? "3vw" : boxWidth}
                        >
                          {awarded && (
                            <Tooltip
                              hasArrow
                              fontSize={"1em"}
                              fontWeight={"bold"}
                              p={3}
                              bg={"white"}
                              textAlign={"center"}
                              color={"black"}
                              placement="bottom"
                              label={
                                <>
                                  {/* <Stack>
                                              <Text>{`Start:\t${date}`}</Text>
                                              <Text>{`End:\t${endOfWeekDate}`}</Text>
                                            </Stack> */}
                                  <Text>{`You got a star because you had ${3} or more activities logged this week`}</Text>
                                </>
                              }
                              aria-label="A tooltip"
                            >
                              <StarIcon color={colorToUse} />
                            </Tooltip>
                          )}
                        </Center>
                      </Box>
                    );
                  })}
                </HStack>
              ))}
            </>
          </Skeleton>
        </Box>
      </Center>
    </Stack>
  );
};

export default ActivityChart;
