import React, { useEffect } from "react";
import {
  AddIcon,
  DeleteIcon,
  MoonIcon,
  SunIcon,
  HamburgerIcon,
  SmallAddIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Center,
  ChakraProvider,
  HStack,
  Stack,
  Select,
  Text,
  Portal,
  Input,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Button,
  FormControl,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useColorMode,
  Switch,
  Skeleton,
} from "@chakra-ui/react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { ActivityChart } from "./components/ActivityChart";
import "./styles/App.css";
import mytheme from "./constants/theme";
import { useLocalStorage } from "./hooks/useLocalStorage";
import { useKeyPress } from "./hooks/useKeyPress";
import OauthPopup from "react-oauth-popup";

let defaultBase = "https://tracky-v0.fly.dev";
if (window.location.host.startsWith("localhost")) {
  defaultBase = "http://localhost:8000";
}

export const BASE_URL = defaultBase;

console.log({ BASE_URL });

const onCode = (code: string, params: any) => {
  console.log("wooooo a code", code);
  localStorage.setItem("code", code);
  console.log(
    "alright! the URLSearchParams interface from the popup url",
    params
  );

  // jump to main page after login happens
  if (window.location.pathname === "/login") {
    window.location.pathname = "/";
  }
};
const onClose = () => console.log("closed!");

function Comp() {
  const urlObject = {
    access_type: "offline",
    client_id:
      "9035376429-p8bdpns0sg865hmgjvkfo604lr12aqou.apps.googleusercontent.com",
    redirect_uri: `${BASE_URL}/redirect`,
    response_type: "code",
    scope: "email", //["profile","email","openid"],
    state: "state",
  };

  // https://accounts.google.com/o/oauth2/auth?
  // access_type=offline&client_id=9035376429-p8bdpns0sg865hmgjvkfo604lr12aqou.apps.googleusercontent.com&
  // redirect_uri=http%3A%2F%2Flocalhost%3A8000%2Fredirect&
  // response_type=code&
  // scope=profile+email+openid&
  // state=state"

  const searchParams = new URLSearchParams(urlObject);
  const host = "https://accounts.google.com/o/oauth2/auth";
  const url = host + "?" + searchParams;
  console.log({ url });

  return (
    <div className="full">
      <div className="inner">
        <div>
          <Text
            fontFamily="Fredoka One"
            fontSize="60px"
            letterSpacing="-2px"
            backgroundClip="text"
            backgroundColor="#AC0"
            backgroundSize="auto"
            backgroundImage="linear-gradient(60deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 10%, rgba(208,222,33,1) 20%, rgba(79,220,74,1) 30%, rgba(63,218,216,1) 40%, rgba(47,201,226,1) 50%, rgba(28,127,238,1) 60%, rgba(95,21,242,1) 70%, rgba(186,12,248,1) 80%, rgba(251,7,217,1) 90%, rgba(255,0,0,1) 100%);"
          >
            mad often
          </Text>
        </div>
        <div className="button-wrapper">
          <OauthPopup
            title="test"
            width={500}
            height={700}
            url={url}
            onCode={onCode}
            onClose={onClose}
          >
            <div id="loginButton" className="button-58">
              Enter
            </div>
          </OauthPopup>
        </div>
      </div>
    </div>
  );
}

function AuthRedirect() {
  return <Comp />;
}

function Main({ isLoggedIn }: any) {
  // const { pathname } = useLocation();
  // let [_, who, what] = pathname.split("/");

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      const code = localStorage.getItem("code");
      if (!code) {
        console.log("You are not logged in redirect to login");
        navigate("/login");
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [isLoggedIn]);

  const { colorMode, toggleColorMode } = useColorMode();

  const [dashboardLocalData, setDashboardLocalData] = useLocalStorage<string>(
    "dashboard",
    JSON.stringify([{ initalWho: "drbh", initalWhat: "floss" }])
  );

  const spotlightRef = React.useRef(null);
  const [showSpotlight, setShowSpotlight] = React.useState(false);

  const [showSummary, setShowSummary] = React.useState(false);
  const [spotlightInput, setSpotlightInput] = React.useState("");
  const [currentSearch, setCurrentSearch] = React.useState("");
  const handelSpotlightInput = (e: any) => {
    // console.log(e);
    setSpotlightInput(e.target.value);
  };

  // capture press and release
  // only change state on each new press
  const spotlight = useKeyPress("k", true);
  React.useEffect(() => {
    console.log({ spotlight, showSpotlight });
    if (spotlight) setShowSpotlight(!showSpotlight);
  }, [spotlight]);

  const handleAddNewEvent = (action: string) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        timestamp: new Date(),
        user: isLoggedIn.email,
        action: action,
      }),
    };
    fetch(`${BASE_URL}/track`, options)
      .then((response) => response.json())
      .then((response) => {
        console.log("Added new event and getting data");
        setShowSpotlight(false);
      })
      .catch((err) => console.error(err));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (e.type === "submit") {
      console.log("pressed!", spotlightInput);
      handleAddNewEvent(spotlightInput);
      // location.assign("http://www.mozilla.org");
    }
  };

  const version = "001";
  return (
    <>
      {isLoggedIn && (
        <Center width="100%">
          <Stack>
            <Stack
              width="100%"
              color="white"
              backgroundColor="#2d0808"
              textAlign={"center"}
              padding={2}
            >
              <p>{`Version ${version}`}</p>
            </Stack>
            <br />
            <HStack bg="transparent">
              <Box width="100%">
                <Text
                  fontFamily="Fredoka One"
                  fontSize="24px"
                  letterSpacing="-2px"
                  backgroundClip="text"
                  backgroundColor="#AC0"
                  backgroundSize="auto"
                  backgroundImage="linear-gradient(60deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 10%, rgba(208,222,33,1) 20%, rgba(79,220,74,1) 30%, rgba(63,218,216,1) 40%, rgba(47,201,226,1) 50%, rgba(28,127,238,1) 60%, rgba(95,21,242,1) 70%, rgba(186,12,248,1) 80%, rgba(251,7,217,1) 90%, rgba(255,0,0,1) 100%);"
                >
                  mad often
                </Text>
              </Box>

              <Menu placement={"bottom-end"}>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<HamburgerIcon />}
                  variant="outline"
                />
                <MenuList>
                  <MenuItem
                    icon={<SmallAddIcon />}
                    onClick={(e: any) => {
                      setDashboardLocalData(
                        JSON.stringify([
                          ...JSON.parse(dashboardLocalData),
                          { initalWho: isLoggedIn.email, initalWhat: "thing" },
                        ])
                      );
                    }}
                  >
                    Add Chart
                  </MenuItem>
                  <MenuItem
                    icon={<DeleteIcon />}
                    onClick={(e: any) => {
                      setDashboardLocalData(JSON.stringify([]));
                    }}
                  >
                    Clear All Charts
                  </MenuItem>
                  <MenuItem
                    onClick={toggleColorMode}
                    icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                  >
                    Toggle Color Mode
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      window.localStorage.removeItem("code");
                    }}
                    icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                  >
                    Sign Out
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
            <br />

            {/* <div>
              <pre>{JSON.stringify(isLoggedIn, null, 2)}</pre>
            </div> */}

            <Portal>
              <Modal
                isOpen={showSpotlight}
                onClose={() => setShowSpotlight(false)}
                initialFocusRef={spotlightRef}
              >
                <ModalOverlay
                  bg="blackAlpha.300"
                  backdropFilter="blur(8px) hue-rotate(-5deg)"
                />
                <Box as={"form"} onSubmit={handleSubmit}>
                  <ModalContent p={2} maxW={"40vw"}>
                    <ModalHeader>Quick Add</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <Input
                        ref={spotlightRef as any}
                        placeholder="floss"
                        value={spotlightInput}
                        onInput={handelSpotlightInput}
                        // onSubmit={handleSubmit}
                      />
                      <Input type="date" />
                    </ModalBody>
                    <ModalFooter>
                      <Button type="submit" colorScheme="green">
                        Save
                      </Button>
                      <Button onClick={() => setShowSpotlight(false)}>
                        Close
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Box>
              </Modal>
            </Portal>

            <Box mt={"10px"} width="100%">
              <HStack>
                <Box width="70%">
                  <Input
                    placeholder="filter visible charts"
                    onChange={(event: any) => {
                      setCurrentSearch(event.target.value);
                    }}
                  />
                </Box>
                <Box width="30%">
                  <Select placeholder="Select option">
                    {[1, 2, 3, 4, 5].map((data: any) => {
                      return (
                        <option key={data} value="option1">
                          personal
                        </option>
                      );
                    })}
                  </Select>
                </Box>
              </HStack>
            </Box>
            <br />
            <Switch
              colorScheme="brand"
              isChecked={showSummary}
              onChange={() => {
                setShowSummary(!showSummary);
              }}
            />
            <br />

            {showSummary && (
              <>
                <Heading>Summary</Heading>
                <ActivityChart
                  initalWho={"david"}
                  initalWhat={"example"}
                  onCloseClick={(e: any) => {}}
                  onTargetUpdate={(data: any) => {}}
                  borderLess={true}
                  badgeLess={true}
                  headerLess={true}
                />
              </>
            )}

            <br />

            <Heading>Charts</Heading>
            {dashboardLocalData &&
              (() => {
                const chartData = JSON.parse(dashboardLocalData);

                const enhancedData = chartData.map((item: any) => ({
                  ...item,
                  isHidden: !(
                    item.initalWhat && item.initalWhat.includes(currentSearch)
                  ),
                }));

                return enhancedData;
              })().map((entry: any, index: number) => {
                return (
                  <ActivityChart
                    key={index}
                    isHidden={entry.isHidden}
                    initalWho={entry.initalWho}
                    initalWhat={entry.initalWhat}
                    onCloseClick={(e: any) => {
                      const newData = JSON.parse(dashboardLocalData);
                      newData.splice(index, 1);
                      setDashboardLocalData(JSON.stringify(newData));
                    }}
                    onTargetUpdate={(data: any) => {
                      console.log(index, data);

                      let newData: any = JSON.parse(dashboardLocalData);
                      newData[index] = {
                        initalWho: data.newWho,
                        initalWhat: data.newWhat,
                      };

                      setDashboardLocalData(JSON.stringify(newData));
                    }}
                  />
                );
              })}
          </Stack>
        </Center>
      )}
    </>
  );
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = React.useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("Checking if logged in");
      const code = localStorage.getItem("code");
      console.log({ code, isLoggedIn });

      // // request to backend to check if logged in
      // const code = localStorage.getItem("isLoggedIn");

      if (!code) {
        console.log("You are not logged in");
      }

      if (code && !isLoggedIn) setIsLoggedIn(parseJwt(code));

      if (isLoggedIn) {
        console.log("your logged in!");
        clearInterval(interval);
        // jump to main page after login happens
        if (window.location.pathname === "/login") {
          window.location.pathname = "/";
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [isLoggedIn]);

  return (
    <ChakraProvider theme={mytheme}>
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<Comp />}></Route>
          <Route path="*" element={<Main isLoggedIn={isLoggedIn} />}></Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;

function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
