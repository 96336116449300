import { useState, useEffect } from "react";

// Hook
export function useKeyPress(targetKey: any, requireMetaKey?: boolean) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState<boolean>(false);
  // If pressed key is our target key then set to true
  function downHandler({ key, metaKey }: any) {
    if (key === targetKey && metaKey) {
      setKeyPressed(true);
    }
  }
  // If released key is our target key then set to false
  const upHandler = ({ key, metaKey }: any) => {
    if (requireMetaKey) {
      if (key === "Meta") setKeyPressed(false);
    } else {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    }
  };
  // Add event listeners
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return keyPressed;
}