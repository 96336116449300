import { EditIcon, SettingsIcon, StarIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Skeleton,
} from "@chakra-ui/react";
import React from "react";

export function IconPicker({ isLoading, color, icon, setIcon }: any) {
  const colors = [
    "gray.500",
    "red.500",
    "gray.700",
    "green.500",
    "blue.500",
    "blue.800",
    "yellow.500",
    "orange.500",
    "purple.500",
    "pink.500",
  ];

  const icons = [
    <StarIcon color={color} />,
    <SettingsIcon color={color} />,
    <EditIcon color={color} />,
    <StarIcon color={color} />,
    <SettingsIcon color={color} />,
    <EditIcon color={color} />,
    <StarIcon color={color} />,
    <SettingsIcon color={color} />,
    <EditIcon color={color} />,
    <StarIcon color={color} />,
  ];
  // const color = colors[0]
  return (
    <>
      <Center marginTop={5}>
        <Popover variant="picker">
          <PopoverTrigger>
            <Skeleton isLoaded={!isLoading}>
              <Button
                aria-label={color}
                background={color + "88"}
                height="22px"
                width="22px"
                padding={0}
                minWidth="unset"
                borderRadius={3}
              >
                {icon}
              </Button>
            </Skeleton>
          </PopoverTrigger>
          <PopoverContent width="170px">
            <Skeleton isLoaded={!isLoading}>
              <PopoverArrow bg={color} />
              <PopoverCloseButton color="white" />
              <PopoverHeader
                height="100px"
                backgroundColor={color + "77"}
                borderTopLeftRadius={5}
                borderTopRightRadius={5}
                color="white"
              >
                <Center height="100%">{icon}</Center>
              </PopoverHeader>
              <PopoverBody height="80px">
                <SimpleGrid columns={5} spacing={2}>
                  {icons.map((c) => (
                    <Button
                      // key={c}
                      // aria-label={c}
                      // background={c}
                      // backgroundColor={color + "77"}
                      borderColor={color}
                      variant="outline"
                      height="22px"
                      width="22px"
                      padding={0}
                      minWidth="unset"
                      borderRadius={3}
                      // _hover={{ background: c }}
                      onClick={() => {
                        setIcon(c);
                      }}
                    >
                      {c}
                    </Button>
                  ))}
                </SimpleGrid>
              </PopoverBody>
            </Skeleton>
          </PopoverContent>
        </Popover>
      </Center>
    </>
  );
}
