// import { extendTheme } from "@chakra-ui/react";
import { mode, StyleConfig } from "@chakra-ui/theme-tools";

// const config = {
//   initialColorMode: 'dark',
//   useSystemColorMode: true,
// }

// const mytheme = extendTheme({
//   ...config,
//   // bg: "red",
//   // colors: {
//   //   brand: {
//   //     100: "#f7fafc",
//   //     // ...
//   //     900: "#1a202c",
//   //   },
//   // },
//   // styles: {
//   //   global: (props: any) => ({
//   //     html: {
//   //       bg: mode("#FAFBFC", "#091E42")(props),
//   //       // bg: colorMode? "#FAFBFC" : "#091E42"
//   //     },
//   //   }),
//   // },
// });

// export default mytheme

import { extendTheme, type ThemeConfig } from "@chakra-ui/react";

// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

// const styles: any = {
//   global: (props: any) => ({
//     html: {
//       bg: mode("#FAFBFC", "#091E42")(props),
//       // bg: props.colorMode ? "#FAFBFC" : "#091E42"
//     },
//   }),
// };

// 3. extend the theme
const theme = extendTheme({ config });

export default theme;
